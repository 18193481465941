html {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

/* .App {
   text-align: center;
} */

/* .App-logo {
   height: 40vmin;
   pointer-events: none;
}
*/

.Connor-logo {
  height: 40vmin;
}

@media (prefers-reduced-motion: no-preference) {
  .clockwise {
    animation: App-logo-spin infinite 5s linear;
  }

  .counter-clockwise {
    animation: App-logo-spin-counter infinite 5s linear;
  }
}

.App-wrapper {
  text-align: center;
}

/*
.App-header {
   background-color: #282c34;
   min-height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   font-size: calc(10px + 2vmin);
   color: white;
}

.App-link {
   color: #61dafb;
}
*/

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin-counter {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

/* body {
   font-family: sans-serif;
}
/* Make content area fill the entire browser window */

/* html,
.fullscreen {
   display: flex;
   height: 100%;
   margin: 0;
   padding: 0;
   width: 100%;
}
#root {
   margin: auto;
   text-align: center;
}

.title {
   font-size: 3rem;
}

*/
